<template>
  <div class="auth">
    <v-col class="col-md-6 col-lg-4 mx-auto">
      <v-card elevation="2" class="md" :loading="loading">
        <v-card-title>{{ 'Register' }}</v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="name"
              :rules="[rules.requiredName]"
              :label="'Name'"
              :error-messages="nameErrors"
            ></v-text-field>
            <v-text-field
              v-model="email"
              :rules="[rules.requiredEmail, rules.email]"
              :label="'E-mail'"
              :error-messages="emailErrors"
            ></v-text-field>
            <v-text-field
              v-model="password"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.requiredPass, rules.min]"
              :type="showPass ? 'text' : 'password'"
              :error-messages="passErrors"
              :label="'Password'"
              counter              
              @click:append="showPass = !showPass"
            ></v-text-field>
            <v-text-field
              v-model="confirmPass"
              type="password"
              :rules="[rules.confirmPass]"
              :label="'Confirm Password'"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn              
              color="primary"
              class="mr-4"
              @click="register"
            >
              {{ 'Register' }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              router 
              to="login"
              text>
              {{ 'Login' }}
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </div>
</template>

<script>

export default {
  data () {
    return {
      name: '',
      email: '',
      password: '',
      confirmPass: '',
      valid: true,
      loading: false,
      showPass: false,
      rules: {
        requiredName: value => !!value || 'Введите имя',
        requiredEmail: value => !!value || 'Введите E-mail адрес',
        requiredPass: value => !!value || 'Введите пароль',
        confirmPass: value => value === this.password  || 'Пароли не совпадают',
        min: value => value.length >= 4 || 'Минимальная длина пароля 4 символа',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Введите корректный E-mail адрес'
        },
      },
    }
  },
  watch: {
    name() {
      this.errors = this.errors.filter(e => e.source.pointer != "name")
    },
    email() {
      this.errors = this.errors.filter(e => e.source.pointer != "email")
    },
    password() {
      this.errors = this.errors.filter(e => e.source.pointer != "password")
    },
    loginStatus() {
      this.loading = false; 
      if (this.loginStatus == 'success') {
        this.$router.push('/');
      }
      if (this.loginStatus == 'loading') {
        this.loading = true;
      }
    }
  },
  computed: {
    errors: {
        get() {
          return this.$store.state.auth.authErrors;
        },
        set(value) {
          this.$store.dispatch('set_errors', value)
        }
    },
    nameErrors: {
        get () {
          return this.errors
            .filter(e => e.source.pointer == "name")
            .map(e => this.$i18n.t(e.detail));
        }
    },
    passErrors: {
        get () {
          return this.errors.filter(e => e.source.pointer == "password");
        }
    },
    emailErrors: {
        get () {
          return this.errors
            .filter(e => e.source.pointer == "email")
            .map(e => this.$i18n.t(e.detail));
        }
    },
    loginStatus: {
        get () {
          return this.$store.getters.authStatus;
        }
    },
  },
  methods: {
    register () {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('register', {email:this.email, password: this.password,name:this.name})
      }
    },
  },
}
</script>
